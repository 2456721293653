import React, { useContext, useEffect } from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { SearchContext } from '../contexts/searchContext'
import { makeStyles } from '@material-ui/core/styles'
import { Layout } from '../component/Layout'
import { PropertyList } from '../component/page_component/PropertyList'
import { useIntl } from 'gatsby-plugin-intl'
import hreflangConfig from '../utils/hreflangConfig'
import { Helmet } from 'react-helmet'
import PreFooterMenu from '../component/page_component/contact/preFooterMenu'
import { useLocation } from '@reach/router'
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    backgroundColor: '#FAE9E5',
    width: '100%',
  },
}))

const Rent: React.FC<PageProps> = (props: any) => {
  const intl = useIntl()
  const {
    location,
    pageContext: { data },
  } = props
  const classes = useStyles()
  const { setSearch, flushSearchState } = useContext(SearchContext)

  const loc = useLocation();

  useEffect(() => {
    if (loc?.hash === "#no-update") {

    } else {
      flushSearchState()
      setSearch({ name: 'transactionType', value: 'rent' })
    }

  }, [])

  const hreflangLinks = hreflangConfig['/fr/biens-en-location/']
  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const params = queryString.parse(loc.search); // Parse query params
  const currentPage = params.page || 1; // Default to page 1 if not set
  const canonicalUrl = `${dataCanonical.site.siteMetadata.siteUrl}${loc.pathname}${currentPage > 1 ? `?page=${currentPage}` : ""}`

 
  return (
    <Layout
      location={location}
      showFlotingMenu={false}
      maxWidth={false}
      title={intl.formatMessage({ id: 'PageTitleRent' })}
    >
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {hreflangLinks &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
        <meta name="description" content="Louer avec nous - Kretz" />
      </Helmet>
      {/* <Grid container className={classes.filterContainer} id="search-for-rental"></Grid> */}
      <PropertyList
        bgClr="#FFF"
        filterBackgroundColor={'#FFF'}
        data={data}
        location={location}
        withFilter={true}
        disableTypeFilter={true}
        title={intl.formatMessage({ id: 'new.Location_5' })}
        description={''}
        image={'louer.jpg'}
        showCTA={false}
        titleOnly
      />
      {/* <Destination />
   
      {/* <SeoContent /> */}
      {/* <Faq /> */}
      <PreFooterMenu />
    </Layout>
  )
}

export default Rent
